<script>
import FormHeader from '../FormHeader'
import TableView from '../TableVIew'
import XLSX from 'xlsx'
import { Button, Tooltip, Icon, Dropdown, Menu, Card, Checkbox, message, Pagination } from 'ant-design-vue'
import api from '@/command/api'
import apiTool from '@/command/apiTool'
import Draggable from 'vuedraggable'
import { getAction } from '@/command/netTool'
export default {
  props: [
    'headerData',
    'button',
    'tableColumns',
    'headerTypeData',
    'records',
    'isShowCard',
    'isShowPage',
    'total',
    'onSelectRow',
    'rightButton',
    'tableProps',
    'showRowSelect',
    'showPagination',
    'filterRightButtonKey',
    'currentRoute',
    'showTitle',
    'bordered',
    'resetDefaultData',
    'rowKey',
    'selectedRowKeys',
    'rowSelection',
    'pageParams',
    'url',
    'onHeaderCallBack',
  ],
  data() {
    return {
      checkeds: new Set([]),
      tableSize: 'middle',
      isFull: false,
      visible: false,
      pageSize: this.$parent.pageSize,
      searchData: {},
      list: this.getInitList(),
      current: 1,
      total: 1,
      records: [],
      params: {},
      dataSource: [],
      tableParams: {},
      headerSearchData:{}
    }
  },
  activated() {
    if (!this.$parent.listUrl) return
    api.command.getList.call(
      this.$parent,
      {
        current: this.$parent.current,
        pageSize: 10,
      },
      this.$parent.paramsValue.host
    )
  },
  mounted() {
    // 初始化列选择
    this.initCheck()
    this.getList(this.pageParams)
  },
  watch: {
    list(newValue) {
      localStorage.setItem(this.$route.fullPath, JSON.stringify(newValue))
    },
  },
  methods: {
    getList(params = this.params) {
      getAction(this.url, { ...(params.isClear ? {} : this.params), ...params }).then((e) => {
        this.dataSource = e.data.records
        delete e.data.records
        this.params = { ...(params.isClear ? {} : this.params), ...params }
        this.tableParams = e.data
      })
    },
    getInitList() {
      const data = localStorage.getItem(this.$route.fullPath)
      if (data) {
        return JSON.parse(data)
      } else {
        return (
          this.tableColumns.map((e) => {
            return {
              name: e.title,
              value: e.dataIndex,
            }
          }) || []
        )
      }
    },
    getHeaderSearchData(){
      return this.headerSearchData
    },
    getColumsStatus(e) {
      if (e.dataIndex !== 'id' && !e.isId) {
        return true
      } else if (e.dataIndex == 'id' && e.isId) {
        return true
      } else {
        return false
      }
    },
    initCheck() {
      this.checkeds = new Set((this.tableColumns || []).filter((e) => this.getColumsStatus(e)).map((e) => e.dataIndex))
    },
    //   菜单点击事件
    onMenuClick(menu) {
      this.tableSize = menu.key
    },
    renderNormal(e) {
      return (
        <Button
          type={e.type}
          ghost={e.ghost}
          icon={e.icon}
          onClick={e.onClick}
          style={{ marginLeft: '10px', ...e.style }}
        >
          {e.name}
        </Button>
      )
    },
    renderNormal2(e) {
      return (
        <Button onClick={() => e.onClick && e.onClick(this.searchData)} style={{ marginLeft: '10px' }}>
          <i class="iconfont icon-xiazai"></i>
          {e.name}
        </Button>
      )
    },
    // 下拉菜单点击事件
    onDropClick(item) {
      this.visible = !this.visible
      this.list = item.typeData
    },
    renderPopup(e) {
      let that = this
      const onChange = (item) => {
        if (that.checkeds.has(item.value)) {
          that.checkeds.delete(item.value)
        } else {
          that.checkeds.add(item.value)
        }
        that.$forceUpdate()
      }
      const cardView = function cardView() {
        return (
          <div style={{ width: '150px' }}>
            <Card title="列展示" size="small" headStyle={{ color: '#333333' }}>
              <a slot="extra" href="#" onClick={that.initCheck}>
                重置
              </a>
              <Draggable list={that.list} group="name3" onEnd={() => console.log('结束', that.list)}>
                {that.list.map((e) => {
                  return (
                    <div class={'header-check-item'}>
                      <Checkbox checked={that.checkeds.has(e.value)} onChange={() => onChange(e)}>
                        {e.name}
                      </Checkbox>
                    </div>
                  )
                })}
              </Draggable>
            </Card>
          </div>
        )
      }
      return (
        <Dropdown visible={this.visible}>
          <Button
            // icon={e.icon}
            onClick={() => this.onDropClick(e)}
            style={{ marginLeft: '10px' }}
          >
            <i class="iconfont icon-canshupeizhi"></i>
            {e.name}
          </Button>
          <template slot="overlay">{cardView()}</template>
        </Dropdown>
      )
    },
    renderMenu(e) {
      return (
        <Dropdown>
          <Tooltip title={e.name} style={{ marginLeft: '10px' }}>
            <Icon type={e.icon} style={{ fontSize: '15px' }} />
          </Tooltip>
          <template slot="overlay">
            <Menu style={{ width: '80px' }} selected-keys={[this.tableSize]}>
              {(e.menu || []).map((el) => {
                return (
                  <Menu.Item key={el.key} onClick={() => e.onClick(el)}>
                    {el.name}
                  </Menu.Item>
                )
              })}
            </Menu>
          </template>
        </Dropdown>
      )
    },
    getDropButtonDown(parent, item) {
      if (parent.isBatch) {
        if (this.$refs.table.selectedRowKeys.length == 0) {
          message.error('请先选择要修改数据')
        } else {
          if (item.onClick) {
            const ret = item.onClick(this.$refs.table.selectedRows)
            if (ret) {
              ret.then(() => (this.$refs.table.selectedRowKeys = []))
            }
          }
        }
      } else {
        item.onClick && item.onClick()
      }
    },
    renderDropDownMenu(item) {
      if (item.viewType !== 'menu' || (Array.isArray(item.children) && item.children.length == 0)) {
        return null
      }
      return (
        <template slot="overlay">
          <Menu>
            {item.children.map((e, i) => {
              return (
                <Menu.Item key={i} onClick={() => this.getDropButtonDown(item, e)}>
                  {e.name}
                </Menu.Item>
              )
            })}
          </Menu>
        </template>
      )
    },
    onSelectedRowKeys(e) {
      if (e.isBatch) {
        if (this.$refs.table.selectedRowKeys.length == 0) {
          message.error('请先选择要修改数据')
        } else {
          if (e.onClick) {
            const ret = e.onClick(this.$refs.table.selectedRows)
            if (ret) {
              ret.then(() => (this.$refs.table.selectedRowKeys = []))
            }
          }
        }
      } else if (e.isType === 'export') {
        if (this.records.length == 0 || this.$refs.table.selectedRowKeys.length == 0) {
          this.$message.error('暂无数据,不可导出！')
          return
        }
        let objParams = {
          ...this.searchData,
        }
        this.$delete(objParams, 'current')
        this.$delete(objParams, 'pageSize')
        e.onClick && e.onClick(objParams)
      } else {
        e.onClick && e.onClick()
      }
    },
    renderLeftButton(e) {
      const buttonStyle = {
        borderColor: 'rgba(232, 234, 236, 1)',
        color: '#515A6E',
      }
      return (
        <Dropdown>
          {this.renderDropDownMenu(e)}
          <Button
            icon={e.icon}
            type={e.type}
            ghost={true}
            onClick={() => e.onClick && this.onSelectedRowKeys(e)}
            style={{
              marginRight: '10px',
              ...(e.viewType == 'menu' && buttonStyle),
              ...e.style,
            }}
          >
            {e.name}
            {e.viewType == 'menu' && <Icon type="down" />}
          </Button>
        </Dropdown>
      )
    },
    getTableSelect() {
      return this.$refs.table ? this.$refs.table.selectedRows : []
    },
    getBase64Image(image, ext) {
      let canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      let context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      // 这里是不支持跨域的
      let base64 = canvas.toDataURL('image/' + ext)
      return base64
    },
    onExportXLSX() {
      const selectTable = this.getTableSelect()
      if (selectTable.length == 0) {
        this.$message.error('请先选择要导出项')
        return
      }

      const data = selectTable.map((e) => {
        const obj = {}
        this.filterColumn().forEach((el) => {
          if (e[el.dataIndex] !== undefined) {
            if (el.onExport) {
              obj[el.title] = el.onExport(e[el.dataIndex], e)
            } else {
              obj[el.title] = e[el.dataIndex]
            }
          }
        })
        return obj
      })
      // 新建空workbook，然后加入worksheet
      const ws = XLSX.utils.json_to_sheet(data)
      // 设置每列的列宽，10代表10个字符，注意中文占2个字符
      ws['!cols'] = [{ wch: 10 }, { wch: 30 }, { wch: 25 }]
      // 新建book
      const wb = XLSX.utils.book_new()
      // 生成xlsx文件(book,sheet数据,sheet命名)
      XLSX.utils.book_append_sheet(wb, ws, '数据详情')
      // 写文件(book,xlsx文件名称)
      XLSX.writeFile(wb, '列表详情.xlsx')
    },
    renderRightButton() {
      const selectTable = this.getTableSelect()
      let rightData = [
        {
          name: `导出Excel(${selectTable.length}条)`,
          icon: 'cloud-download',
          type: 'normal2',
          key: 'export',
          onClick: this.onExportXLSX,
        },
        {
          name: '刷新',
          icon: 'reload',
          type: 'normal',
          key: 'refresh',
          onClick: () => {
            this.getList({ pageSize: this.pageSize, current: 1 })
          },
        },
        {
          name: '列设置',
          icon: 'filter',
          type: 'popup',
          key: 'setting',
          typeData: this.tableColumns.map((e) => {
            return {
              name: e.title,
              value: e.dataIndex,
            }
          }),
        },
      ]

      if (this.filterRightButtonKey) {
        rightData = rightData.filter((e) => this.filterRightButtonKey.indexOf(e.key) !== -1)
      }
      if (this.rightButton && Array.isArray(this.rightButton)) {
        rightData.unshift(...this.rightButton)
      }
 
      return rightData.map((e) => {
        switch (e.type) {
          case 'normal':
            return this.renderNormal(e)
          case 'normal2':
            return this.renderNormal2(e)
          case 'menu':
            return this.renderMenu(e)
          case 'popup':
            return this.renderPopup(e)
          default:
            return this.renderNormal(e)
        }
      })
    },
    // 筛选按钮
    filterButton(button) {
      const buttonName = apiTool.getButtonName()
      return button.filter((e) => {
        if (e.viewType == 'menu') {
          const mChildren = e.children.filter((el) => buttonName.some((ea) => el.name.indexOf(ea) !== -1))
          e.children = mChildren
          if (mChildren.length > 0) {
            return true
          } else {
            return false
          }
        }
        return buttonName.indexOf(e.name) != -1
      })
    },
    renderButton() {
      if (!this.button) return null
      //* ************************************** */
      let left = []
      if (process.env.NODE_ENV === 'development') {
        left = this.button.map((e, i) => this.renderLeftButton(e, i))
      } else {
        left = this.filterButton(this.button).map((e, i) => this.renderLeftButton(e, i))
      }
      console.log('left', left)
      const right = this.renderRightButton()
      return (
        <div class="header-button">
          <span>{left}</span>
          <span>{right}</span>
        </div>
      )
    },
    filterColumn() {
      //   console.log('this.tableColumns', this.tableColumns, this.list)
      //   return this.list
      //     .map(el => (this.tableColumns || []).find(e => e.dataIndex == el.value))
      //     .filter(e => this.checkeds.has(e.dataIndex))
      return this.tableColumns.filter((e) => this.checkeds.has(e.dataIndex))
    },
    //* *添加请求接口的表头筛选 */
    searchChange(e, data) {
      this.searchData = { ...this.searchData, ...data }
      api.command.getList.call(
        this.$parent,
        {
          current: 1,
          pageSize: this.pageSize,
          params: {
            ...this.searchData,
            ...this.$parent.paramsValue,
          },
        },
        this.$parent.paramsValue.host
      )
    },
    renderTable() {
      return (
        <TableView
          ref={'table'}
          size={this.tableSize}
          loading={this.$parent.loading}
          styles={{ marginTop: '20px' }}
          columns={this.filterColumn()}
          dataSource={this.dataSource}
          onSelectRow={this.$listeners.selectRow}
          props={this.tableProps}
          showRowSelect={this.showRowSelect}
          showPagination={false}
          bordered={this.bordered}
          rowKey={this.rowKey}
          selectedRowKeys={this.selectedRowKeys}
          onSelectedRows={this.handleSelectedRows}
          rowSelection={this.rowSelection}
        />
      )
    },
    renderHeader() {
      if (!(this.headerData instanceof Array && this.headerData.length > 0)) return null
      return (
        <FormHeader
          data={this.headerData}
          resetDefaultData={this.resetDefaultData}
          onSuccess={(data) => {
            if (this.$listeners.headerCallBack) {
              this.getList({
                ...this.pageParams,
                ...data,
                ...this.$listeners.headerCallBack(data),
                current: 1,
                pageSize: 10,
              })
            } else {
              this.headerSearchData = {...this.pageParams, ...data}
              this.getList({ ...this.pageParams, ...data, current: 1, pageSize: 10 })
            }
          }}
          onReset={() => {
            this.headerSearchData = {...this.pageParams}
            this.pageSize = 10
            this.getList({ ...this.pageParams, current: 1, pageSize: 10, isClear: true })
          }}
          typeData={this.headerTypeData}
          ref="header"
        />
      )
    },
    renderPagination() {
      const scopedSlots = {
        buildOptionText: (props) => {
          return <div>{props.value !== '999999' ? <span>{props.value}条/页</span> : <span>全部</span>}</div>
        },
      }
 
      return (
        <div class="template-pagination">
          <Pagination
            total={this.tableParams.total}
            current={this.tableParams.current}
            showSizeChanger={true}
            pageSize={this.pageSize || 10}
            pageSizeOptions={['10', '20', '30', '40', '999999']}
            onShowSizeChange={(data, pageSize) => {
              this.pageSize = pageSize
              this.getList({ ...this.pageParams,...this.headerSearchData, current: 1, size: pageSize })
            }}
            onChange={(data, pageSize) => {
              this.pageSize = pageSize
              this.getList({ ...this.pageParams,...this.headerSearchData, current: data, size: pageSize })
            }}
            {...{ scopedSlots }}
          />
        </div>
      )
    },
    renderTitle() {
      if (!this.showTitle) return null
      return this.showTitle.context.getTitle?.() || null
    },
    renderBody() {
      return (
        <div>
          {this.renderHeader()}
          {this.renderButton()}
          {this.renderTitle()}
          {this.renderTable()}
        </div>
      )
    },
  },
  render() {
    if (this.isShowCard != undefined && !this.isShowCard) {
      return this.renderBody()
    }
    return (
      <div class="template-card">
        <Card bordered={false}>{this.renderBody()}</Card>
        {!this.showPagination && this.renderPagination()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/.ant-btn {
  border-radius: 5px;
}

/deep/.ant-tag {
  margin-top: 0px !important;
}

.template-card {
  border: 1px solid rgba(232, 234, 236, 1);
  border-radius: 4px;
  min-height: calc(100vh - 128px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  padding-bottom: 20px;
}

.icon-canshupeizhi {
  font-size: 12px;
  margin-right: 8px;
  color: #000;
  font-weight: 900;
  transition: color 0.45s;
}

.icon-xiazai {
  font-size: 14px;
  margin-right: 8px;
  color: #000;
  transition: color 0.45s;
}

/deep/.ant-btn:hover .icon-xiazai {
  color: #40a9ff;
}

/deep/.ant-btn:hover .icon-canshupeizhi {
  color: #40a9ff;
}

.table-main {
  display: flex;
  flex: 1;
  background: red;
}

.header-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  i {
    cursor: pointer !important;
  }

  span {
    display: flex;
    align-items: center;
  }
}

.header-check-item {
  display: flex;
  align-items: center;
  min-height: 30px;
  justify-content: flex-start;
}

/deep/.anticon.anticon-plus {
  font-size: 12px;
}

/deep/.ant-btn {
  line-height: 1;
}

.template-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
