import api from '@/command/api'
import orderConfig from '@/orderConfig'
import { getAction } from '@/command/netTool'


export const scenicAreasTicketsMixin = {
    methods: {
        // 获取选中订单详情
        getDetailDataScenicAreasTickets(orderId, orderItemId) {
            return new Promise((resolve, reject) => {
                getAction(`/api/ticketOrder/detail?orderId=${orderId}&orderItemId=${orderItemId}`)
                    .then((result) => {
                        resolve(result.data)
                    })
                    .catch((e) => reject && reject(e))
            })
        },
        // 获取套餐详情
        getPackageDetailScenicAreasTickets(id) {
            return new Promise((resolve, reject) => {
                getAction(`/api/combo/order/detail?id=${id}`)
                    .then((result) => {
                        resolve(result.data)
                    })
                    .catch((e) => reject && reject(e))
            })
        },
        onRowSelectScenicAreasTickets(parent, orderItemId,isDetail) {
            const ext = [
                // 表格
                orderConfig.tableTickets,
                // 可选择表格
                orderConfig.tableTicketsSelect,
                // 基础信息
                orderConfig.baseTicket,
                // 用户表格
                orderConfig.tableTicketsInfo,
                // 第三方票务
                orderConfig.thirdPartyTicket,
                // 使用记录
                orderConfig.recordList,
                // 记录
                orderConfig.recordTicket,
            ]
            const onDetail = (e) => {
                let showButton = false
                if (isDetail){
                    showButton = false
                }else {
                    if (e.fromType != '14'){
                        showButton = true
                    }
                }
                orderConfig.open({
                    data: e,
                    params: {
                        data: { ...e, showButton:showButton },
                        // customOrderId: parent.id,
                        // customOrderItemId: orderItemId,
                    },
                    executeFun: ext,
                    onHidden: () => {
                        this.getInit()
                    },
                    foot: ({ close, submit }) => {
                        return [
                            {
                                name: '取消',
                                onClick: close,
                            },
                        ]
                    },
                })
            }
            if (parent.fromType == '14') {
                this.getPackageDetailScenicAreasTickets(parent.id).then((detail) => {
                    detail.orderItemList = detail.comboDetails.map((e) => ({
                        ...e,
                        productName: e.hotelRoomName ? e.productName + '/' + e.hotelRoomName : e.businessName + '/' + e.productName,
                        productPic: '  ',
                        totalPrice: e.salePrice,
                    }))
                    onDetail(detail)
                })
            } else {
                this.getDetailDataScenicAreasTickets(parent.id, orderItemId).then(onDetail)
            }
        },
    }
}
